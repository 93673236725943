.outer{
  /* background-color: #e5f9ff; */
  height: 100vh;
  margin: 0;
}
.wrapper{
  display: flex;
  width: 40%;
  margin:auto;
  /* padding-top: 10%; */
  min-height: 50%;
  /* background-color: #e5f9ff; */
  margin-top: 5%;
}
.index-h-1{
  text-align: center;
  color: #026882;
}
.wrapper .index-overlap-group2{
  background-color: #138ead;
  width: 50%;
}
.wrapper .index-overlap-group3{
  width: 50%;
  background-color: #fff;
  border: 2px solid #138ead;
}
.wrapper .right-cntr{
  width: 80%;
  margin: auto;
  margin-top: 25%;
  
}

.wrapper .right-cntr .link{
  margin-bottom: 2rem;
  font-size: 13px;
}
.wrapper .right-cntr .link .index-p{
  text-decoration: none;
  color: #138ead;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.wrapper .right-cntr .btn{
  width: 100%;
  background-color: #138ead;
  padding: 0.3rem 0;
  text-align: center;
  color: #e5f9ff;
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 14px;
  cursor: pointer;
}

/* Registration form */
/* .register-container{
  height: 100vh;
} */
.div-container{
  width: 55%;
  margin: 3rem auto;
  border: 2px solid #138ead;
  padding: 2rem;
  border-radius: 10px;
  /* min-height: 60%; */
}
.div-container .r-heading{
  color: #026882;
}
.div-container .form-container{
  width: 100%;
  /* margin-top: 10%; */
}

/* .div-container .subscription{
  display: flex;
  justify-content: space-between;
}
.div-container .subscription .input-container{
  width: 48%
} */
.user-form .input-container{
  margin-right: 1rem;
}
/* .u-input{
  width: 85%;
  width: 24.6%;
  margin-right: 0.7rem;
}
.p-input{
  width: 85%;
}
.s-input{
  width: 100%;
  padding: auto 0;
} */
.div-container .btn-container{
  margin: 0;
  padding: 0;
}
.long-btn{
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #138ead;
  color: #e5f9ff;
  font-weight: 600;
  cursor: pointer;
}
.r-inner-form{
  border: 0.5px solid lightgray;
  border-radius: 5px;
  padding: 0.5rem;
  min-height: 1rem;
  margin-bottom: 1rem;
}
.user-form{
  margin-bottom: 1rem;
  display: flex;
}
.remove-btn{
  margin-top: 2%;
}
.remove-btn button{
  border: 2px solid #138ead;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  color: #026882;
  font-weight: 800;
  cursor: pointer;
}

.coming-soon{
  width: 100%;
  text-align: center;
}
.coming-soon h3{
  font-weight: 700;
  font-size: 22px;
  color: blue;
}
.coming-soon h4{
  font-weight: 700;
  font-size: 18px;
  color: blue;
}

.disclaimer{
  display: flex;
}
.disclaimer-text{
  width: 90%;
  text-align: justify;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 11px;
}
.dis-btn{
  padding: 0.3rem 1rem;
  border: none;
  background-color: #138ead;
  border-radius: 5px;
  margin-left: 2rem;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
}
.r-btn-container{
  margin-top: 2rem;
}
.disabled{
  background-color: rgb(178, 178, 178);
}
.err-msg-login{
  font-size: 13px;
  color: red;
}
