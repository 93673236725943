.video-container{
    width: 80%;
    margin: auto;
}
#User-Guide-video{
    margin-bottom: 2rem;
}
.demo-text{
    margin-bottom: 4rem;
    margin-top: 4rem;
}
.demo-text h2{
    margin-bottom: 1rem;
}
.main-video-container{
    background-Color:#f9f9f9;
    padding: 5rem;
    border: 1px solid #efefef;
    border-radius:5px
}
.main-video-container .player{
    box-Shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
    border-Radius:5px
}

@media only screen and (max-width: 600px) {
    .video-container{
        width: 90%;
    }
    .main-video-container{
        padding: 1rem;
    } 
    #footer{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .footer-nav-links{
        width: 100%;
        margin-bottom: 2rem;
    }
}