body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .container{
  display: flex;
}
.container aside{
  width:15%;
}
.parent{
 
  margin: 0 auto;
  flex: 2;
  padding: 1rem 3rem;
} */

/* popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}
.popup{
  position: fixed;
  z-index: 1;
  top: 5%;
  left:35%;
  width: 40%;
  background: #fff;
  border: none;
  border-radius: 1rem;
  overflow-y: auto;
  padding: 1rem;
  height: 70vh;
}
.popup1,.popup2{
  display: none;
}
.popup textarea {
  /* width: 90%; */
  margin: auto;
  overflow: auto;
  padding: 1rem;
}
.popup .form-input{
 
  border: 0.5px solid rgb(233, 233, 233);
}
select{
  padding: 0.3rem;
  text-indent: 5px;
}
/* .popup-btn-container{
  padding: 0 1rem;
  display: none;
  margin-bottom: 2rem;
}
.buttons{
  display: flex;
  justify-content: space-between;
} */
.buttons button{
  padding:0.4rem 1rem;
  background-color: white;
  border: 1px solid #1390ae;
  border-radius: 5px;
  font-weight: 600;
  color: #1390ae;
  cursor: pointer;
}
.labelForTemplate{
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 14px;
}
.popup-header{
  display: flex;
  /* background-color: #1390ae; */
  /* padding: 0.5rem; */
  margin: 0;
  justify-content: space-between;
  
}
.popup-header label{
  font-size: 16px;
  font-weight: bold;
  color: #1390ae;
}
.popup-header button{
  background-color: #1390ae;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  padding: 0.6rem;
  margin: 0;
  width:6%;
  margin: 0.5rem;
  border-radius: 4rem;
  border: none;
}
.popup-top-container{
  /* background-color: red; */
  width: 98%;
  margin: auto;
  /* display: flex;
  justify-content: space-between; */
}
.popup-form-fields{
  padding: 1rem;
}
.popup .input-container{
  padding: 0.3rem;
  margin-bottom: 0;
}
.popup .selectContainer{
  padding: 0.3rem;
  width: 100%;
}
.error-message{
  color: rgb(187, 3, 3);
}
.success-message{
  color: green;
}
.msg-background{
  padding: 0.3rem 1rem;
  background-color: rgb(184, 253, 184);
  border-radius: 5px;
}


.session-popup-header{
  font-size: 16px;
  font-weight: 600;
}

.loader-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background: rgb(177, 235, 250,0.4);
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}
.loader{
  width: 20%;
  height: 20%;
}

#root{
  background-color: #fff !important;
}
main{
  /* margin-left: 20%; */
  padding-left: 16%;
  background-color: #fff;
}
.ant-layout-sider-trigger{
  background-color: #1390ae !important;
}
.ant-menu-title-content{
  font-size: 13.5px ;
  font-weight: 550;
}

/* navbar new */
.header-new{
  display: flex;
  justify-content: space-between;
  background-color: #b1ebfa;
}
.header-right{
  margin-top: 1rem;
  padding: 1rem;
}
.header-right a{
padding: 1rem 2rem;
text-decoration: none;
color: #04333e;
font-weight: 600;
}

#login{
  background-color: #16a8cd;
  color: white;
  border-radius: 5px;
}
.header-home{
  background-color: transparent; 
}
.header-link{
  color: white !important;
}

.a-form,.d-form,.s-form,.rp-form,.se-form,.ca-form,.sa-form{
position: relative;
}


.remove-btn{
  position: absolute;
  border: 1px solid #1390ae;
  border-radius: 5px;
  background-color: white;
  width: 5%;
  min-height: 10%;
  padding: 0.3rem;
  right: 0;
  top: 0.5rem;
}
.single-box{
  display: flex;
}

.heading{
  font-size: 16px;
}

.icon{
  font-size: 100px;
 margin-left: 35%;
}
.success-icon{
  color: green;
}
.failure-icon{
  color: red;
}
.payment-div{
  width: 30%;
  margin: auto;
  margin-top: 10rem;
  padding: 5rem;
  background-color: #e5f9ff
}
.payment-text{
  text-align: center;
  font-weight: 500;
  font-size: 22px;
}
.payment-link{
  width: 90%;
  background-color: #1390ae;
  padding: 0.7rem;
  margin: auto;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}
.payment-link .link{
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.payment-msg-hidden{
  display: none;
}
.payment-msg-show{
  display: block;
}
.payment-cntr{
  display: flex;
}
.payment-cntr .input-container{
  width: 50%;
}
