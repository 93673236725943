*{
  font-family: 'Inter';
}
.form-container{
  width: 50%;
  margin-top: 2rem;
  /* margin: 2rem auto; */

}
.form{
  width: 100%;
  /* width: 17rem; */
  margin-right: 1rem;
  display: flex;
}
.input-container{
    display:flex;
    flex-direction:column;
    margin-bottom: 1rem;
    /* width: 100%; */
}
.form-input{
    /* width: 100%; */
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 0.3rem 1rem;
}
.multiple-input-container{
  margin-bottom: 1rem;
}
.multiple-input-container .inner-form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 1rem;
  }
  .multiple-input-container .inner-form .d-form,.multiple-input-container .inner-form .rp-form{
    display: flex;
    align-items: flex-start;
  }
  .multiple-input-container .inner-form .form .form-input,.multiple-input-container .inner-form .d-form .form-input,.multiple-input-container .inner-form .rp-form .form-input{
    margin-right: 2rem;
  }
 /* .multiple-input-container .inner-form .s-form .form-input,.multiple-input-container .inner-form .sa-form .form-input,.multiple-input-container .inner-form .ca-form .form-input,.multiple-input-container .inner-form .se-form .form-input{
    width: 80%;
    margin-bottom: 0;
  } */
  .form-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1rem;
  }
  .form-header button{
    padding: 0.3rem 1rem;
    border-radius: 5px;
    border: none;
    background-color: #1390ae;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0.2rem;
  }
  label{
    font-size: 13px;
    margin-bottom: 0.2rem;
    font-weight: 550;
  }
  
  .form-header button i{
    margin-right: 0.5rem;
  }
  .form-header label{
    /* margin-bottom: 0.2rem; */
    font-size: 13px;
  }

  /* Company list */
  .list-container{
    margin: 2rem;
  }
  /* table{
    width: 100%;
    padding: 0.5rem;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  .search{
    display: flex;
    width: 90%;
    justify-content: space-between;
  }
  .input-icons i {
    position: absolute;
  }
  
  .input-icons {
    width: 40%;
  }
  
  .icon {
    padding: 0.5rem 0;
    min-width: 40px;
    font-size: 13px;
    color: #000;
  }
  .actions a{
    color: #000;
  }
  
  .search-input {
    width: 100%;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0.4rem 0 0.4rem 2rem;
  } */
  /* Search company table */
  .anticon{
    text-align: center;
  }
  .anticon-edit,.anticon-field-time{
  color: black;
 }
 .anticon-edit:hover,.anticon-field-time:hover{
  color: #1390ae;
 }
 .anticon-delete:hover{
  color: red;
 }
 
  thead th{
    background-color: #E6FAFF !important;
  }
  .tble-hdr-row{
    background-color: #0885A5;
  }
 
  /* tbody tr:nth-child(even){
    background-color: #E6FAFF;
  } */
  .ant-table-cell:last-child span{
    margin: 0 25%;
    font-size: 18px;
    
  }
  .search{
    background-color: #E6FAFF;
  }
  
  .actions{
    display: flex;
    justify-content: space-around;
  }
  .actions .icon-hover-edit:hover{
    color:#1390ae;
    cursor: pointer;
  }
  .actions .icon-hover-trash:hover{
    color:red;
    cursor: pointer;
  }
  .btn-container{
    display: flex;
    justify-content: flex-end;
    /* padding: 1rem; */
  }
  .btn-container button{
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #1390ae;
    border: none;
    border-radius: 5px;
    color: #E6FAFF;
    cursor: pointer;
  }
  .company-submit{
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #1390ae;
    border: none;
    border-radius: 5px;
    color: #E6FAFF;
    cursor: pointer;
    font-weight: 550;
  }
  .shareholder-msg{
    color: orangered;
    font-size: 15px;
    font-weight: bolder;
  }
  .msg-div{
    margin-bottom: 1rem;
  }