.historyTable{
    margin-top: 2rem;
}
.heading{
    margin-top: -1rem;
}
.user-dashboard-header{
    display: flex;
    justify-content: space-between;
}
.btn-primary{
    /* padding: 0.5rem 1rem; */
    border: none;
    border-radius: 7px;
    background-color: #138FAD;
    color: #E6FAFF;
    font-weight: 500;
    cursor: pointer;
}
.nw-btn-lnk{
    height: fit-content;
    padding: 0.5rem 1rem;
    background-color: #138FAD;
    border-radius: 5px;
    cursor: pointer;
}
.nw-btn-lnk .link{
    color: white;
}
.popup-text-container{
    width: 95%;
}
.popup-text-container ol li{
    margin-bottom: 1rem;
    text-align: justify;
    font-size: 13px;
}
.ins-popup-heading{
    margin-left: 1rem;
}
.ins-btn{
    margin-right: 1rem;
}

.data-text{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    padding: 0.3rem;
}
.data-text-date{
    font-size: 16px;
    font-weight: 800;
}
.data-heading-container{
    position: relative;
    margin-bottom: 6rem;
}
.data-heading-container .data-card-container{
    /* background-color: red; */
    width: 23%;
    /* margin-bottom: 1rem; */
    position: absolute;
    top:0;
    right: 0;
    text-align: center;
    padding: 0.8rem;
    border-radius: 10px;
    background-color: #FF4433;
    color: #E6FAFF;
}
.data-card-container{
    display: flex;
    flex-direction: column;
}
.data-card-container h4{
    padding: 0.5rem 0;
}
/* .password-popup{
    padding: 1rem 1.5rem;
}
.password-popup-header{
    padding: 0;
    
}
.pwd-popup-heading{
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
}
.pwd-popup{
    width:20%
} */