.subsr-form-container{
    width: 100%;
  margin:auto;
  /* border: 2px solid #138ead; */
  padding: 1rem;
  border-radius: 10px;
  min-height: 60%;
}

.subsr-form-container .inner-form-container{
    /* display: flex; */
    justify-content: space-between;
}
.button-div{
   margin-top: 1rem;
   margin-bottom: 3rem;
}
.button-div .button{
    background-color: #138ead;
    border: none;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    float: right;
    margin-bottom: 2rem;

}
.button-div .large-button{
    float: right;
}

  .hideMsg{
    display: none;
    
  }
  .msg-div{
    background-color: rgb(255, 224, 224);
    border: none;
    border-radius: 5px;
    padding: 0.5px;
  }
  .err-msg{
    color: rgb(201, 18, 18);
    font-weight: bold;
    text-align: center;
  }
  /* tr:nth-child(even) {
    background-color: #dddddd;
  } */
  .payment-form{
    display: flex;
    flex-direction: column;
  }

  /* Yearly packages */
  .yearly-packages-heading{
    margin-top:1rem;
    margin-bottom: 1rem;
  }
  .yearly-packages{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* background-color: antiquewhite; */
    margin-top: 2rem;
    /* margin-bottom: 2rem; */
  }
  .yearly-packages .package-container{
    width: 24%;
    border: 2px solid #138ead;
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
  .action-btn{
    width: 47%;
    border: 3px solid #138ead;
    border-radius: 5px;
    padding: 1rem;
    margin:20px 20px 20px 0;
    background-color: White;
    cursor: pointer;
    box-shadow: 2px 2px 2px #dadada;
    font-size: 16px;
    font-weight: 600;
    color: #138ead;
  }
  .action-btn:hover{
    background-color: aliceblue;
    box-shadow: 5px 10px 18px #dadada;
    color: black;
  }
  .package-desc{
    padding: 1rem;
  }
  .package-desc li{
    margin: 2rem;
    font-size: 13px;
  }
  .package-heading{
    text-align: center;
    margin-bottom: 1rem;
  }

  .message-container{
    margin-top: 1rem;
    text-align: center;
  }
  .message-container .payment-message{
    font-size: 13px;
  }
  .message-container .price-message{
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
    font-size: 28px;
    font-weight: 900;
  }
  .subscribe-btn{
    margin-top: 1rem;
    margin-bottom: 3rem;
    width: 100%;
  }
  .features-heading{
    font-size: 16px;
  }

  .package-container hr{
    margin: auto;
    height: 0.2rem;
    background-color: #138ead;
    outline: none;
    border-radius: 0.1rem;
  }

  .trial-form{
      width: 95%;
      margin: auto;
      padding: 4% 10%;
      border: 2px solid #138ead;
      border-radius: 10px;
  }
  .trial-form h2{
    margin-bottom: 2rem;
  }
  .trial-form-hidden{
    display: none;
  }
  .pre-table{
    width: 95%;
    margin: auto;
  }
  .standard-package{
    position: relative;
  }

  .best-selling-heading{
    position: absolute;
    top:-3%;
    left: 25%;
    text-align: center;
    font-weight: 600;
    background-color: #138ead;
    padding: 0.5rem 1rem;
    color: white;
    font-size: 16px;
    border-radius: 20px;
    width: 10rem;
  }
  .example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .payment-message-container{
    width: 98%;
    margin: auto;
    text-align: center;
    padding: 1rem;
    margin-top:2rem;
    margin-bottom:2rem;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  .success-message{
    background-color: lightgreen;
  }
  .warning-message{
    background-color: orange;
  }
  .failed-message{
    background-color: red;
  }