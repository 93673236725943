.pwd-form-container{
    width: 25%;
    margin: 10% auto;
    border: 2px solid #138ead;
    border-radius: 5px;
    padding: 1rem 2rem 4rem 2rem;
    position: relative;
}
.sub-button{
    float: right;
    background-color: #138ead;
    border: none;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
}

.err-msg{
    display: none;
}
.show-msg{
    display: block;
    background-color: rgb(255, 195, 195);
    color: rgb(155, 10, 10);
    padding: 1rem;
    text-align: center;
    border-radius: 5px;
}
.gray{
    background-color: gray;
}

/* .header-new{
    display: flex;
    justify-content: space-between;
    background-color: #dcf8ff;
}
.header-right{
    margin-top: 1rem;
    padding: 1rem;
}
.header-right a{
padding: 1rem 2rem;
text-decoration: none;
color: #138ead;
font-weight: 600;
}

#login{
    background-color: #16a8cd;
    color: white;
    border-radius: 5px;
} */