* {
    font-family: "Inter", sans-serif;
  }
  
  .main-container {
    width: 100%;
  }
  /* NAVBAR START */

 .nav-container nav{
  display: flex;
  justify-content: space-between;
 }
 /* .nav-container nav .nav-logo{
  padding: 0.5rem;
  display: flex;
 }
 .nav-container nav .nav-logo h3{
  margin-left: 1rem;
  margin-top: 0;
  font-size: 20px;
  color: #fff;
 }
 .nav-container nav .nav-logo .img-container{
  width: 20%;
  height: 10%;
 }
 .nav-container nav .nav-logo img{
 
 } */
.nav-logo{
  width: 5%;
 }
  .nav-logo .img-container{
  width: 100%;
  height: 25%;
 }
.nav-logo Lottie{
  margin-bottom: 0;
 }
  .nav-logo h3{
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 20px;
  color: #fff;
 }
 .nav-container nav ul li{
  color:#fff;
  text-decoration: none;
  list-style: none;
  margin-right: 1rem;
  font-weight: 600;
  font-size: 17px;
  padding: 0.5rem;
 }
 .nav-container nav ul li a{
  text-decoration: none;
  color: #fff;
 }
 .nav-container nav ul li a:hover{
    cursor: pointer;
 }
 .nav-container nav .login-button{
  background-color: #138fad;
  border-radius: 5px;
 font-size: 16px;
 max-height: 20%;
 }
 .nav-container nav .login-button:hover{
  color: #fff !important;
 }
.reg-outer .nav-container{
  background-color: #b1ebfa;
  height: 12%;
}
.reg-outer .nav-container ul li a{
  color: #000;
}
.reg-outer .nav-container .nav-logo h3{
  color: #000;
}
  /* NAVBAR END */

  /* FOOTER START */
  #footer{
    background-color: #E6FAFF;
    padding: 1rem;
    color: #013340;
    display: flex;
    justify-content: space-evenly;
  }
  .footer-details{
    /* width: 50%; */
  }
  .footer-details h3{
    font-size: 16px;
  }
  .footer-details p{
    font-size: 13px;
    line-height: 20px;
  }
  .footer-nav-links ul{
    list-style-type: none;
  }
  .footer-nav-links ul li{
    padding: 0.5rem;
    font-weight: 500;
  }
  .footer-nav-links ul a{
    text-decoration: none;
    color: #013340;
    font-size: 14px;
  }
  .footer-logo{
    width: 30%;
  }
  .footer-logo .nav-logo{
    width: 20%;
  }
  .footer-logo .nav-logo .img-container{
    width: 100%;
   height: 0;
   margin-bottom: 3.5rem;
    background-color: red;
  }
  .footer-logo .nav-logo h3{
    margin-left: 1rem;
    font-size: 24px;
  }
  .footer-nav-links{
    width: 20%;
  }
  /* FOOTER END */
  .home {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../public/christina-wocintechchat-com-faEfWCdOKIg-unsplash.jpg');
    background-size: cover;
    height: 70vh;
    background-position: center center;
    /* background: url('../../../public/christina-wocintechchat-com-faEfWCdOKIg-unsplash.jpg'); */
    /* background-position: center center;
    background-size: cover; 
    background-position: center center;
    background-size: cover; 
    position: relative;
    width: 100%;
    height: 70vh;
    background-color: #E6FAFF; */
  }
  .text {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    text-align: center;
  }
  
  .home-container {
    display: flex;
    width: 100%;
    margin: auto;
    
  }
  
  .home-text {
    width: 65%;
    margin: 10rem auto;
  }
  
  .home-header {
    font-size: 32px;
    margin-bottom: 2rem;
    /* text-align: center; */
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .home-para {
    font-size: 18px;
    /* text-align: center; */
    color: #fff;
    margin: auto;
    font-weight: 300;
    width: 60%;
    text-align: center;
    line-height: 1.5rem;
    letter-spacing: 0.5px;
  }
  
  .home-button {
    align-items: center;
    background-color: #013340;
    border: none;
    color: white;
    padding: 0.5rem 4rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 1rem 40%;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .home-image {
    width: 50%;
    margin-top: 16rem;
  }
  
  .home-image img {
    min-width: 80%;
    float: right;
  }
  .animation-div{
    width: 12%;
    margin: 1rem auto;
  }
 
  
  /* ABOUT - START */
  
  
  .about-container {
    display: flex;
    width: 80%;
    margin: 5rem auto ;
  }
  .abt-left-container{
    width: 60%;
    position: relative;
    margin: auto;
    margin-right: 2rem;
   /* margin-top: 5rem; */
  }
  .about-text {
    width: 50%;
  }
  .emptyDiv{
    border: 2px solid #013340;
    width: 55%;
    min-height: 60%;
  }
  .about-image {
    width: 55%;
    top: 9%;
    left: 8%;
    border: 5px solid #000;
    position: absolute;
    min-height: 60%;
    /* background-color: #000; */
    background: url('../../../public/about-small.jpg');
    background-size: cover;
  }
  
  .about-image img {
    min-width: 80%;
    float: left;
  }
  
  .about-our-story {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .about-our-story p {
    color: #013340;
    font-size: 20px;
    margin-top: 1rem;
    font-weight: 600;
  }
  
  .vertical-line {
    border-left: 5px solid #013340;
    height: 30px;
    margin-top: 1rem;
    padding-right: 1rem;
    display: inline;
    float: left;
    clear: right;
  }
  
  .about-header {
    font-size: 24px;
    color: #024859;
  }
  
  .message {
    color: #013340;
    font-size: 20px;
    margin-top: 1rem;
    font-weight: 600;
  }
  
  .about-para {
    font-size: 16px;
    color: #026888;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }
  
  .about-button {
    background-color: #138fad;
    border: none;
    color: white;
    padding: 0.5rem 4rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
/* CRISP OBJECT - START */
.crisp-object{
 background-color: #E6FAFF;
  padding: 5rem 0;
}
.crisp-header{
font-weight: bold;
margin-bottom: 4rem;
}
.card-container{
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  padding-top: 1rem;
}
.card-container .card{
  text-align: center;
  width: 22%;
  margin: 0 1rem 2rem 1rem;
  min-height: 20%;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: #cef5ff;
}
.card-heading .heading{
  text-align: center;
}
.card-text{
  width: 92%;
  margin: auto;
}
.card-text .para{
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 0.5rem;
  /* background-color: red; */
}
.card span{
  text-align: center;
  margin-top: 1rem;
  font-size: 32px;
}
/* CRISP OBJECT - END */

  /* EASEOPT MINUTES - START */
  
  .easeopt {
    /* background-color: #e6faff; */
    padding: 5rem;
    /* height: 100vh; */
  }
  
  .easeopt-container {
    display: flex;
    /* width: 80%; */
    justify-content: space-between;
    margin: auto;
  }
  
  .easeopt-text {
    width: 60%;
    margin: 0 1rem;
    /* margin-top: 16rem; */
    font-weight: bold;
  }
  
  .easeopt-header {
    font-size: 32px;
    color: #024859;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .easeopt-para {
    font-size: 16px;
    font-weight: normal;
    color: #0885a5;
    margin-bottom: 1rem;
  }
  
  .easeopt-button {
    width: 100%;
    background-color: #138fad;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 0.5rem 4rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  
  .easeopt-image {
    width: 50%;
    /* margin-top: 20rem; */
  }
  
  .easeopt-image img {
    min-width: 80%;
    float: right;
  }
  .ease-opt-animation{
    width: 40%;
    margin: 1rem;
  }
 .terms-text{
  font-size: 13px;
  color: #024859;
  font-weight: bold;
 } 
  .material-symbols-outlined{
    margin-bottom: 1rem !important;
  }
