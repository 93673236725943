#outer-container{
    display: flex;
    /* width: 90%;
    margin-left: 10% !important; */
    margin-left: 5%;
}
#outer-container .left{
    width:50%
}
#outer-container .right{
    overflow-x: auto;
}
#generate-min-heading{
    margin-left: 5% !important;

}
.divider{
    min-height: 100%;
    border: 1px solid #d9d9d9;
    margin-left: 1.5rem;
}
.no-preview{
    padding: 1rem 3rem;
    border: 1px solid #d9d9d9;
    margin-left: 1rem;
    /* margin-top: 2rem; */
    border-radius: 5px;
    text-align: center;
    color: #138FAD;
    font-weight: 600;
}
.popup-btn-container .buttons{
    display: flex;
    justify-content: space-between;
}

.agenda-items{
    border: 1px solid #138FAD;
    border-radius: 10px;
    padding: 1rem 2rem;
}
.list-cntr{
    
    margin-bottom: 0.5rem;
}
.list-cntr li{
    font-size: 13px;
}
.list-cntr{
    display: flex;
    justify-content: space-between;
}
.list-cntr button{
    background-color: #fff;
    border: 1px solid #138FAD;
    border-radius: 5px;
    font-size: 11px;
    font-weight: bold;
    color: #138FAD;
    cursor: pointer;
}
.agenda-text p{
    font-size: 13px;
    font-weight: 500;
}
.table-text{
    display: block;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 15px;
}
.load-parameters{
    margin-top: 1rem;
}


@media only screen and (max-width: 1024px)  {
    #outer-container{
        display: flex;
        /* width: 90%;
        margin-left: 10% !important; */
        margin-left: 5%;
    }
    #outer-container .left{
        width:45%
    }
    #outer-container .right{
        overflow-x: auto;
    }
    #generate-min-heading{
        margin-left: 5% !important;
    
    }
}