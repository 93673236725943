.template-parameters{
    margin-top: 2rem;
}
input.toggle-round {
    display:none;
  }
  input.toggle-round + label {
    display: block;
    position: relative;
    cursor: pointer;
  }
  input.toggle-round + label {
    padding: 1px;
    width: 50px;
    height: 25px;
    background-color: #ddd;
    border-radius: 25px;
    /* border: 1px solid #ccc; */
  }
  input.toggle-round + label:after {
    display: block;
    position: absolute;
    content: "";
    width: 25px; height: 25px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 2px 3px #888;
    transition: margin 0.5s;
  }
  input.toggle-round:checked + label {
    background-color: #138fad;
  }
  input.toggle-round:checked + label:after {
    margin-left: 23px;
  }