*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.links li{
    list-style: none;
}
.links li a{
    text-decoration: none;
    color: white;
    font-size: 0.9rem;
}

.nav-main{
    position: relative;
    padding: 0 2rem;
}
.nav-main .navbar{
    width:100%;
    height: 60px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.nav-main .navbar .links{
    display: flex;
    gap: 1.5rem;
}
.nav-main .navbar .toggle-btn{
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}
.nav-main .action-btn{
    background-color: #138FAD;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    margin-left: 2rem;
    transition: scale .2 ease;
}
.nav-main .action-btn:hover{
    scale: 1.05;
    color: #fff;
}
.nav-main .action-btn:active{
    scale: 0.95;
}




.user{
    margin-bottom: 2rem;
}
.section-container{
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-bottom: 2rem;
    height: 60vh;
}
.section-container .left-section{
    width: 50%;
}
.section-container .right-section{
    width: 45%;
}
.section-container .left-section .img-container{
    width: 100%;
    object-fit: contain;
    border: 0.2px solid #138FAD;
    margin-top: 2rem;
}
.section-container .left-section .img-container img{
    width: 100%;
    height: 100%;
}

.section-container .left-section .video-container video{
    width: 90%;
    height: 100%;
    outline: none;
    border: 1px solid #000;
    padding: 1rem;
}

.video-player{
    border: 0.2px solid #138FAD;
    margin-bottom: 1rem;
}
  .user-text-content{
    text-align: center;
    margin-top: 10%;
  }
/* NAVBAR START */

/* .user-nav-container nav{
    display: flex;
    justify-content: space-between;
   }
   .user-nav-container nav .nav-logo{
    padding: 0.5rem;
    display: flex;
   }
   .user-nav-container nav .nav-logo h3{
    margin-left: 1rem;
    margin-top: 0;
    font-size: 20px;
    color: #fff;
   }
   .user-nav-container nav .nav-logo .img-container{
    width: 20%;
    height: 10%;
   }
   .user-nav-container nav .nav-logo img{
   
   }
   .user-nav-container nav ul{
    display: flex;
   }
   .user-nav-container nav ul li{
    text-decoration: none;
    list-style: none;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 17px;
    padding: 0.5rem;
   }
   .user-nav-container nav ul li a{
    text-decoration: none;
    color: #fff;
   }
   .user-nav-container nav ul li a:hover{
      color: #138fad;
      cursor: pointer;
   }
   .user-nav-container nav .login-button{
    background-color: #138fad;
    border-radius: 5px;
   font-size: 16px;
   padding: 0.5rem 1rem;
   color: #fff;
   }
   .user-nav-container nav .login-button:hover{
    color: #fff;
   } */
  
    /* NAVBAR END */

.section-container .right-section p{
    font-size: 14px;
}
    .section-container .right-section ol li{
        margin-bottom: 0.5rem;
        font-size: 14px;
    }

.demo-text {
    width: 85%;
    margin: 5rem auto;
}
#User-Guide-video .video{
    margin-bottom: 5rem;
}
/* Drop down menu */
.dropdown-menu{
    display: none;
    position: absolute;
    right: 2rem;
    top: 60px;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    height: 0;
    overflow: hidden; 
    transition: height 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);   
}
.dropdown-menu.open{
    height: 320px;
}
.dropdown-menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.dropdown-menu li a{
    text-decoration: none;
    color: #fff;
    
}
.dropdown-menu .action-btn-dp{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #138FAD;
    padding: 0.5rem;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-bottom: 1rem;
}

    @media only screen and (max-width: 600px) {
        .dropdown-menu{
            display: block;
        }
       .nav-main .navbar .links, .action-btn{
        display: none;
       }

       .nav-main .navbar .toggle-btn{
        display: block;
       }

        .main-video-container{
            width:85%;
             margin:auto;
             padding: 20px;
             border: 1px solid #efefef;
             border-Radius:5px;
             background-Color:#f9f9f9
           
        }
        .demo-text{
            width:85%;
             margin:auto;
        }
        .section-container{
           width: 95%;
           display: flex;
           flex-direction: column;
           margin: auto;
           padding: 0;
        }
        .section-container .right-section{
            width: 95%;
            margin: 2rem auto;
        }
        .section-container .col-2{
            margin-top: 15rem;
        }
        .section-container .left-section{
            width: 95%;
            margin: 1rem auto;
        }
    }    