.animation{
    margin-top: 35%;
}
.query-right-cntr{
    padding: 1rem;
}
.query-index-large-button .index-overlap-group .btn button{
    width: 100%;
    background-color:#138fad;
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    border:none;
}
/* .query-index-large-button .index-overlap-group .btn button{
   text-decoration: none;
   color: #fff;
    font-weight: 600;
    border: none;
} */